<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-xl-4 order-lg-1 order-xl-1" v-permission="'contract.view'">
        <!--begin:: Widgets/Activity-->
        <KTPortlet
                v-bind="{
              title: 'Form',
              // class: 'kt-portlet--fit kt-portlet--skin-solid',
              // headClass: 'kt-portlet__head--noborder kt-portlet__space-x',
              // headSize: 'lg',
              fluidHeight: true,
              // headOverlay: true,
              // headNoBorder: true
            }"
        >
          <template v-slot:body>
              <b-button class="mb-3"  v-if="item.form.length > 10" v-for="item in documents" :key="item.id" :to="{name:'document-form', params:{'id':item.id}}"  variant="outline-primary">{{item.label}}</b-button>
          </template>
        </KTPortlet>
        <!--end:: Widgets/Activity-->
      </div>
      <div class="col-lg-6 col-xl-4 order-lg-1 order-xl-1" v-permission="'contract.view'">
        <!--begin:: Widgets/Activity-->
        <KTPortlet
                v-bind="{
              title: 'Lista Documenti',
              // class: 'kt-portlet--fit kt-portlet--skin-solid',
              // headClass: 'kt-portlet__head--noborder kt-portlet__space-x',
              // headSize: 'lg',
              fluidHeight: true,
              // headOverlay: true,
              // headNoBorder: true
            }"
        >
          <template v-slot:body>
            <b-button :to="{name:'document-list'}" class="mr-3" variant="outline-primary">Vai alla lista</b-button>
          </template>
        </KTPortlet>
        <!--end:: Widgets/Activity-->
      </div>

      <div class="col-lg-6 col-xl-4 order-lg-1 order-xl-1" v-permission="'contract.log.view'">
        <!--begin:: Widgets/Activity-->
        <KTPortlet
                v-bind="{
              title: 'Registro Documenti',
              // class: 'kt-portlet--fit kt-portlet--skin-solid',
              // headClass: 'kt-portlet__head--noborder kt-portlet__space-x',
              // headSize: 'lg',
              fluidHeight: true,
              // headOverlay: true,
              // headNoBorder: true
            }"
        >
          <template v-slot:body>
            <b-button :to="{name:'document-history'}" class="mr-3 mb-3" variant="outline-primary">Vai allo storico invii</b-button>
            <b-button v-if="featureDraftEnabled" :to="{name:'document-history-drafts'}" class="mr-3 mb-1" variant="outline-primary">Vai alle bozze</b-button>
          </template>
        </KTPortlet>
        <!--end:: Widgets/Activity-->
      </div>

    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "../../common/api.service";
import { FEATURE_DRAFT_ENABLED } from "@/common/config";

export default {
  name: "dashboard",
  components: {
    KTPortlet
  },
  data() {
    return {
      featureDraftEnabled: FEATURE_DRAFT_ENABLED,
      documents: [

      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.reloadList();
  },
  methods: {
    reloadList(){
      ApiService.get('contracts')
          .then(({ data }) => {
            this.documents = data.rows.reverse().sort(function(x, y) {
              let xprio = 10;
              let yprio = 10;
              let optsdatax = x.opts ? JSON.parse(x.opts) : {};
              xprio = 'Priority' in optsdatax && optsdatax.Priority!='' ? optsdatax.Priority : xprio;
              let optsdatay = y.opts ? JSON.parse(y.opts) : {};
              yprio = 'Priority' in optsdatay && optsdatay.Priority!='' ? optsdatay.Priority : yprio;
              if (xprio < yprio) {
                return -1;
              }
              if (xprio > yprio) {
                return 1;
              }
              return 0;
            });
          })
          .catch(({ response }) => {
            //context.commit(SET_ERROR, response.data.errors);
          });
    },
  }
};
</script>

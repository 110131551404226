var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{directives:[{name:"permission",rawName:"v-permission",value:('contract.view'),expression:"'contract.view'"}],staticClass:"col-lg-6 col-xl-4 order-lg-1 order-xl-1"},[_c('KTPortlet',_vm._b({scopedSlots:_vm._u([{key:"body",fn:function(){return _vm._l((_vm.documents),function(item){return (item.form.length > 10)?_c('b-button',{key:item.id,staticClass:"mb-3",attrs:{"to":{name:'document-form', params:{'id':item.id}},"variant":"outline-primary"}},[_vm._v(_vm._s(item.label))]):_vm._e()})},proxy:true}])},'KTPortlet',{
            title: 'Form',
            // class: 'kt-portlet--fit kt-portlet--skin-solid',
            // headClass: 'kt-portlet__head--noborder kt-portlet__space-x',
            // headSize: 'lg',
            fluidHeight: true,
            // headOverlay: true,
            // headNoBorder: true
          },false))],1),_c('div',{directives:[{name:"permission",rawName:"v-permission",value:('contract.view'),expression:"'contract.view'"}],staticClass:"col-lg-6 col-xl-4 order-lg-1 order-xl-1"},[_c('KTPortlet',_vm._b({scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('b-button',{staticClass:"mr-3",attrs:{"to":{name:'document-list'},"variant":"outline-primary"}},[_vm._v("Vai alla lista")])]},proxy:true}])},'KTPortlet',{
            title: 'Lista Documenti',
            // class: 'kt-portlet--fit kt-portlet--skin-solid',
            // headClass: 'kt-portlet__head--noborder kt-portlet__space-x',
            // headSize: 'lg',
            fluidHeight: true,
            // headOverlay: true,
            // headNoBorder: true
          },false))],1),_c('div',{directives:[{name:"permission",rawName:"v-permission",value:('contract.log.view'),expression:"'contract.log.view'"}],staticClass:"col-lg-6 col-xl-4 order-lg-1 order-xl-1"},[_c('KTPortlet',_vm._b({scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('b-button',{staticClass:"mr-3 mb-3",attrs:{"to":{name:'document-history'},"variant":"outline-primary"}},[_vm._v("Vai allo storico invii")]),(_vm.featureDraftEnabled)?_c('b-button',{staticClass:"mr-3 mb-1",attrs:{"to":{name:'document-history-drafts'},"variant":"outline-primary"}},[_vm._v("Vai alle bozze")]):_vm._e()]},proxy:true}])},'KTPortlet',{
            title: 'Registro Documenti',
            // class: 'kt-portlet--fit kt-portlet--skin-solid',
            // headClass: 'kt-portlet__head--noborder kt-portlet__space-x',
            // headSize: 'lg',
            fluidHeight: true,
            // headOverlay: true,
            // headNoBorder: true
          },false))],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }